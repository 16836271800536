import React, { forwardRef } from 'react'
import { Form as BSForm, InputGroup } from 'react-bootstrap'

import { useOnChange } from '../../hooks/useOnChange'
import { FormContextType, IFormDataVals } from '../../types'

export type TextProps = {
    controlId: string
    className?: string
    autocomplete?: boolean
    required?: boolean
    minLength?: number
    pattern?: string | { ( form: FormContextType ): string | undefined }
    disabled?: boolean
    readOnly?: boolean
    plaintext?: boolean
    isValid?: boolean | { ( form: FormContextType ): boolean }
    isInvalid?: boolean | { ( form: FormContextType ): boolean }
    placeholder?: string
    invalidMsg?: string
    prefix?: string | React.ReactNode
    suffix?: string | React.ReactNode
    onChange?: { ( oldVal?: IFormDataVals, newVal?: IFormDataVals ): void }
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextProps>( ({ controlId, className, invalidMsg, disabled, required, autocomplete, pattern, isValid, isInvalid, prefix, suffix, onChange, ...props }: TextProps, ref ) => {

    const { form, onControlChange } = useOnChange( controlId, 'value', onChange )

    return(<BSForm.Group controlId={ controlId } className={ [ 'mb-3', className ].join( ' ' ) }>
        <InputGroup>
            { typeof prefix === 'string' && <InputGroup.Text>{ prefix }</InputGroup.Text> }
            { typeof prefix === 'object' && prefix }
            <BSForm.Control 
                ref={ ref }
                { ...props }
                as='textarea'
                value={ form ? form.data[ controlId ] as string : '' }
                onChange={ onControlChange }
                autoComplete={ autocomplete ? 'on' : 'off' }
                required={ required }
                pattern={ form && typeof pattern === 'function' ? pattern( form ) : typeof pattern === 'string' ? pattern : undefined }
                disabled={ disabled || form?.processing }
                isValid={ form && form.validated && typeof isValid === 'function' ? isValid( form ) : typeof isValid === 'boolean' ? isValid : undefined }
                isInvalid={ form && form.validated && typeof isInvalid === 'function' ? isInvalid( form ) : typeof isInvalid === 'boolean' ? isInvalid : undefined }
            />
            { typeof suffix === 'string' && <InputGroup.Text>{ suffix }</InputGroup.Text> }
            { typeof suffix === 'object' && suffix }
            { invalidMsg && <BSForm.Control.Feedback type='invalid'>{ invalidMsg }</BSForm.Control.Feedback> }
        </InputGroup>
    </BSForm.Group>)
} )