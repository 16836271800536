import { useCallback } from 'react'

import { useForm } from './useForm'
import { IFormDataVals } from '../types'

export const useOnChange = ( controlId: string, type: 'value' | 'checked' | 'literal' | 'file', onChange?: ( oldVal?: IFormDataVals, newVal?: IFormDataVals ) => void  ) => {
    const form = useForm()

    const onControlChange = useCallback( ( ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | IFormDataVals | FileList ) => {
        let val: IFormDataVals = ''
        let fls: Record<string, FileList> = {}
        if( type === 'literal' ) val = ev as IFormDataVals
        else if( type === 'checked' && typeof ev == 'object' ) val = ((ev as React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>).currentTarget as HTMLInputElement).checked
        else if( type === 'value' && typeof ev === 'object' ) val = ((ev as React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>).currentTarget).value
        else if( type === 'file' && typeof ev === 'object' ) {
            if( ev !== null ) {
                val = Array.from( ev as FileList ).map( f => f.name ).join( '; ' )
                fls[ `${ controlId }_upload` ] = ev as FileList
            }
        }
        const oldVal = form?.data[ controlId ] 
        form?.setData({ ...form.data, [ controlId ]: val, ...fls }) 
        // call onChange only if value has changed
        if( oldVal != val && onChange ) onChange( oldVal, val )
    }, [ form, controlId ] )

    return { form, onControlChange }
}