import React, { useCallback, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Row, Col } from 'react-bootstrap'

import { useFocus } from '../../../Common'
import { useNotifications } from '../../../Notifications'
import { useAuth } from '../../../Auth'
import { Form, Text, FormContextType, useUrlFrom } from '../../../Forms'
import { AUTH_CONFIG } from '../../contexts/AuthContextProvider'
import { usePwdReset } from '../../hooks/usePwdReset'

export const PwdReset = () => {

    const intl = useIntl()
    const title = intl.formatMessage({ description: 'auth.pwd-reset.page-title', defaultMessage: 'Password Reset' })

    const navigate = useNavigate()

    const auth = useAuth()
    if( !auth || typeof auth.pwdReset === 'undefined' ) {
        navigate( '/log-in', { replace: true } )
    }

    const urlFrom = useUrlFrom()

    const { addNotification, removeAllNotifications } = useNotifications()

    const [ pwdResetRun, pwdResetRequestState ] = usePwdReset( urlFrom )

    const onFinish = ( form: FormContextType ) => pwdResetRun( form.data[ 'username' ] as string )
    
    useEffect( () => {
        if( auth?.isAuthenticated ) navigate( '/', { replace: true } )
    }, [ auth, navigate ] )

    const body = <>
        { AUTH_CONFIG.pageHeading ? <AUTH_CONFIG.pageHeading title={ title }>{ title }</AUTH_CONFIG.pageHeading> : <h1>{ title }</h1> }
        { AUTH_CONFIG.pageStatus && <AUTH_CONFIG.pageStatus 
            loading={ pwdResetRequestState.processing }
            error={ pwdResetRequestState.isSuccess ? undefined : pwdResetRequestState.message }
        /> }
        <Form
            formName='pwd_reset'
            onFinishCallback={ onFinish }
            initialData={{ username: '' }}
        >
            <Text 
                ref={ useFocus }
                controlId='username' 
                type='email'
                className='mb-3'
                placeholder={ intl.formatMessage( { description: 'auth.username', defaultMessage: 'Username' } ) }
                required
                invalidMsg={ intl.formatMessage({ description: 'auth.username-required', defaultMessage: 'Please input your Username!' })}
            />
            <Row className='mb-3'>
                <Col size={ 12 } md={ 5 }>
                    <Button type='submit' className='pwd-reset-form-button'>
                        <FormattedMessage description='auth.pwd-reset' defaultMessage='Reset password' />
                    </Button>
                </Col>
                <Col className='text-end' size={ 12 } md={ 7 }>
                    <FormattedMessage description='auth.or' defaultMessage='Or' />&nbsp;<Link to='/log-in' replace={ true }  state={ { from: urlFrom } }><FormattedMessage description='auth.pwd-reset.return-login' defaultMessage='Return to Log-in' /></Link>
                </Col>
            </Row>
        </Form>
    </>

    return AUTH_CONFIG.viewWrapper ? <AUTH_CONFIG.viewWrapper>{ body }</AUTH_CONFIG.viewWrapper> : body
}