import React from 'react'

import DatePickerOrig, { registerLocale } from 'react-datepicker'
import { cs } from 'date-fns/locale'
registerLocale( 'cs', cs )

import { useOnChange } from '../../hooks/useOnChange'
import { IFormDataVals } from '../../types'
import { on } from 'events'

type DatePickerExtProps = {
    controlId: string
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    dateFormat?: string;
    isClearable?: boolean;
    clearButtonTitle?: string;
    showIcon?: boolean;
    showWeekNumbers?: boolean;
    showTimeSelect?: boolean;
    showTimeInput?: boolean;
    onChange?: { ( oldVal?: IFormDataVals, newVal?: IFormDataVals ): void }
}

export const DatePicker = ( { controlId, onChange, ...props }: DatePickerExtProps ) => {

    const { form, onControlChange } = useOnChange( controlId, 'literal', onChange )

    return(<DatePickerOrig
        locale='cs'
        showPopperArrow={ false }
        { ...props }
        className={ `form-control text-center ${ props.className ?? '' }` }
        showIcon={ props.showIcon ?? true }
        dateFormat={ props.dateFormat ?? 'dd.MM.yyyy' }
        isClearable={ props.isClearable ?? true }
        showWeekNumbers={ props.showWeekNumbers ?? true }
        selected={ form ? form.data[ controlId ] as Date : new Date() }
        onChange={ onControlChange }
        withPortal={ true }
    />)
}